
<template>
  <div id="box">
    <h1>抑郁量表问卷</h1>
    <p>在每项问题中，请选出最能形容你在过去感受的选项：</p>
    <div>
      <h4>1.抑郁情绪</h4>
      <el-radio-group v-model="radio">
        <el-radio label="0">A：无 </el-radio><br />
        <el-radio label="1">B：只有问到时才倾诉 </el-radio><br />
        <el-radio label="2">C：在言语中自发地表达 </el-radio><br />
        <el-radio label="3"
          >D: 不用言语也可从表情、姿势、声音或欲哭中流露出这种情绪</el-radio
        ><br />
        <el-radio label="4"
          >E：自发语言和非自发语言（表情、动作）表达几乎完全表现为这种情绪</el-radio
        ><br /></el-radio-group
      ><br />
      <h4>2.有罪感</h4>
      <el-radio-group v-model="radio1">
        <el-radio label="0">A：无 </el-radio><br />
        <el-radio label="1">B：责备自己，感到自己已连累他人 </el-radio><br />
        <el-radio label="2"
          >C：认为自己犯了罪，或反复思考以往的过失和错误</el-radio
        ><br />
        <el-radio label="3"
          >D: 认为目前的疾病，是对自己错误的惩罚，或有罪恶妄想</el-radio
        ><br />
        <el-radio label="4">E：罪恶妄想伴有指责或威胁性幻觉</el-radio
        ><br /></el-radio-group
      ><br />
      <br />
      <h4>3.自杀</h4>
      <el-radio-group v-model="radio2">
        <el-radio label="1">A：无 </el-radio><br />
        <el-radio label="2">B：觉得活着没有意义 </el-radio><br />
        <el-radio label="3">C：希望自己已经死去，或常想到与死有关的事 </el-radio
        ><br />
        <el-radio label="4">D：消极观念（有自杀念头） </el-radio><br />
        <br /></el-radio-group
      ><br />
      <br />
      <h4>4.入睡困难</h4>
      <el-radio-group v-model="radio3">
        <el-radio label="0">A：入睡无困难</el-radio><br />
        <el-radio label="1"
          >B：有时有入睡困难，即上床后半小时仍不能入睡</el-radio
        ><br />
        <el-radio label="2">C：每晚均有入睡困难</el-radio><br />
        <br /></el-radio-group
      ><br />
      <br />
    </div>
    <br />
    <h4>5.睡眠不深</h4>
    <el-radio-group v-model="radio4">
      <el-radio label="0">A：无 </el-radio><br />
      <el-radio label="1">B：睡眠浅多恶梦</el-radio><br />
      <el-radio label="2"
        >C：半夜（晚上12点以前）曾醒来（不包括上厕所）</el-radio
      ><br /> </el-radio-group
    ><br />
    <h4>6.早醒</h4>
    <el-radio-group v-model="radio5">
      <el-radio label="0">A：无 </el-radio><br />
      <el-radio label="1">B：有早醒，比平时早醒1小时，但能重新入睡</el-radio
      ><br />
      <el-radio label="2">C：早醒后无法重新入睡</el-radio
      ><br /> </el-radio-group
    ><br />
    <h4>7.工作和兴趣</h4>
    <el-radio-group v-model="radio6">
      <el-radio label="0">A：无 </el-radio><br />
      <el-radio label="1">B：提问时才诉述</el-radio><br />
      <el-radio label="2"
        >C：自发地直接或间接表达对活动、工作或学习失去兴趣，感到没精打采，犹豫不决，不能坚持或需强迫自己去工作或活动</el-radio
      ><br />
      <el-radio label="3"
        >D：活动时间减少或成效降低，每天参加劳动或娱乐不足3小时</el-radio
      ><br />
      <el-radio label="4"
        >E：因目前的疾病而停止工作，患者不参加任何活动或者没有他人帮助便不能完成病室日常事务</el-radio
      ><br /></el-radio-group
    ><br />
    <h4>8.迟缓（思维和语言缓慢，注意力难以集中，主动性减退）</h4>
    <el-radio-group v-model="radio7">
      <el-radio label="0">A：思维和语言等正常 </el-radio><br />
      <el-radio label="1">B：精神检查中发现轻度迟缓</el-radio><br />
      <el-radio label="2">C：精神检查中发现明显迟缓</el-radio><br />
      <el-radio label="3">D：精神检查进行困难</el-radio><br />
      <el-radio label="4">E：完全不能回答问题</el-radio><br /> </el-radio-group
    ><br />
    <h4>9.激越</h4>
    <el-radio-group v-model="radio8">
      <el-radio label="0">A：未出现异常 </el-radio><br />
      <el-radio label="1">B：检查时表现的有些心神不定</el-radio><br />
      <el-radio label="2">C：明显的心神不定或小动作多</el-radio><br />
      <el-radio label="3">D：明显的心神不定或小动作</el-radio><br />
      <el-radio label="4">E：搓手，咬手指，扯头发，咬嘴唇</el-radio><br />
      <br /> </el-radio-group
    ><br />
    <h4>10.精神性焦虑</h4>
    <el-radio-group v-model="radio9">
      <el-radio label="0">A：无 </el-radio><br />
      <el-radio label="1">B：问到才倾诉</el-radio><br />
      <el-radio label="2">C：自发地表达</el-radio><br />
      <el-radio label="3">D：表情和言谈流露明显忧虑</el-radio><br />
      <el-radio label="4">E：明显惊恐</el-radio><br />
      <br /> </el-radio-group
    ><br />
    <h4>
      11.躯体性焦虑：指焦虑的生理症状，包括口干、腹胀、腹泻、打呃、腹绞痛、心悸、头痛、过度换气和叹息、以及尿频和出汗等
    </h4>
    <el-radio-group v-model="radio10">
      <el-radio label="0">A：无 </el-radio><br />
      <el-radio label="1">B：轻度</el-radio><br />
      <el-radio label="2">C：中度，有肯定的上述症状</el-radio><br />
      <el-radio label="3">D：重度，上述症状严重，影响生活或需加处理</el-radio
      ><br />
      <el-radio label="4">E：严重影响生活和活动</el-radio><br />
      <br /> </el-radio-group
    ><br />
    <h4>12.胃肠道症状</h4>
    <el-radio-group v-model="radio11">
      <el-radio label="0">A：无 </el-radio><br />
      <el-radio label="1">B：食欲减退，但不需他人鼓励便自行进食 </el-radio
      ><br />
      <el-radio label="2"
        >C：进食需他人催促或请求或需要应用泻药或助消化药</el-radio
      ><br /> </el-radio-group
    ><br />
    <h4>13.全身症状</h4>
    <el-radio-group v-model="radio12">
      <el-radio label="0">A：无 </el-radio><br />
      <el-radio label="1"
        >B：四肢、背部或颈部沉重感，背痛，头痛，肌肉疼痛, 全身乏力或疲倦 </el-radio
      ><br />
      <el-radio label="2">C：上述症状明显</el-radio><br /></el-radio-group
    ><br />
    <h4>14.性症状(指性欲减退、月经紊乱等)</h4>
    <el-radio-group v-model="radio13">
      <el-radio label="0">A：无异常 </el-radio><br />
      <el-radio label="1">B：轻度 </el-radio><br />
      <el-radio label="2">C：重度</el-radio><br /></el-radio-group
    ><br />
    <h4>15.疑病</h4>
    <el-radio-group v-model="radio14">
      <el-radio label="0">A：无 </el-radio><br />
      <el-radio label="1">B：对身体过分关注</el-radio><br />
      <el-radio label="2">C：反复考虑健康问题</el-radio><br />
      <el-radio label="3">D：有疑病妄想</el-radio><br />
      <el-radio label="4">E：伴幻觉的疑病妄想</el-radio></el-radio-group
    ><br />
    <h4>16.体重减轻</h4>
    <el-radio-group v-model="radio15">
      <el-radio label="0">A：没什么变化 </el-radio><br />
      <el-radio label="1">B：一周内体重减轻1斤以上 </el-radio><br />
      <el-radio label="2">C：一周内体重减轻2斤以上</el-radio
      ><br /></el-radio-group
    ><br />
    <h4>17.自知力</h4>
    <el-radio-group v-model="radio16">
      <el-radio label="0">A：知道自己有病，表现为忧郁</el-radio><br />
      <el-radio label="1"
        >B：知道自己有病，但归于伙食太差、环境问题、工作过忙、病毒感染或需要休息等 </el-radio
      ><br />
      <el-radio label="2">C：完全否认有病</el-radio><br /></el-radio-group
    ><br />
    <h4>18.日夜变化（症状在早晨或傍晚加重）</h4>
    <el-radio-group v-model="radio17">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度变化</el-radio><br />
      <el-radio label="2">C：重度变化</el-radio><br /> </el-radio-group
    ><br />
    <h4>19.人格解体或现实解体：指非真实感或虚无妄想</h4>
    <el-radio-group v-model="radio18">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：问及时才诉述</el-radio><br />
      <el-radio label="2">C：自发诉述</el-radio><br />
      <el-radio label="3">D：有虚无妄想</el-radio><br />
      <el-radio label="4">E：伴幻觉的虚无妄想</el-radio><br /></el-radio-group
    ><br />
    <h4>20.偏执症状</h4>
    <el-radio-group v-model="radio19">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：有猜疑</el-radio><br />
      <el-radio label="2">C：有关系观念</el-radio><br />
      <el-radio label="3">D：有关系妄想或被害妄想</el-radio><br />
      <el-radio label="4">E：伴有幻觉的关系妄想或被害妄想</el-radio
      ><br /></el-radio-group
    ><br />
    <h4>21.强迫症状：指强迫思维和强迫行为</h4>
    <el-radio-group v-model="radio20">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：问及时才诉述</el-radio><br />
      <el-radio label="2">C：自发诉述</el-radio><br /> </el-radio-group
    ><br />
    <h4>22.能力减退感</h4>
    <el-radio-group v-model="radio21">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：仅于提问时方引出主观体验</el-radio><br />
      <el-radio label="2">C：主动表示能力减退感</el-radio><br />
      <el-radio label="3"
        >D：需鼓励、指导和安慰才能完成病室日常事务或个人卫生</el-radio
      ><br />
      <el-radio label="4"
        >E：穿衣、梳洗、进食、铺床或个人卫生均需他人协助</el-radio
      ><br /></el-radio-group
    ><br />
    <h4>23.绝望感</h4>
    <el-radio-group v-model="radio22">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：有时怀疑“情况是否会好转”，但解释后能接受</el-radio
      ><br />
      <el-radio label="2">C：持续感到“没有希望”，但解释后能接受</el-radio><br />
      <el-radio label="3"
        >D：对未来感到灰心、悲观和绝望，解释后不能排除</el-radio
      ><br />
      <el-radio label="4"
        >E：自动反复诉述“我的病不会好了”或诸如此类的情况</el-radio
      ><br /></el-radio-group
    ><br />
    <h4>24.自卑感</h4>
    <el-radio-group v-model="radio23">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：仅在询问时诉述有自卑感（我不如他人）</el-radio
      ><br />
      <el-radio label="2">C：自动诉述有自卑感（我不如他人）</el-radio><br />
      <el-radio label="3">D：主动诉述：“我一无是处”或“低人一等”</el-radio><br />
      <el-radio label="4"
        >E：自卑感达妄想的程度，例如“我是废物”类似情况</el-radio
      ><br /></el-radio-group
    ><br />
    <el-button type="success" @click="ExamSolutionADD" :disabled="isdisabled"
      >提交</el-button
    >
  </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
  mounted() {
    this.get();
  },
  updated() {
    // console.log("总分：", this.total);
    // console.log("说明", this.explain);
  },
  methods: {
    async ExamSolutionADD() {
      const data = await axios({
        url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=抑郁量表评估&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=${this.explain}&Review=暂无建议&Section=/&Doctor=/`,
        method: "post",
        data,
      });
      if (data.data.data == true) {
        this.isShow = !this.isShow;
        // 存储HAMD的分数到本地存储
        const HAMDScore = this.total;
        localStorage.setItem("HAMD", JSON.stringify(HAMDScore));
        Message.success("提交成功，进入下一个量表评估");
        this.$router.push("/BAI");
      }
    },
    //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
    get() {
      const patientN = localStorage.getItem("patientN");
      const patientI = localStorage.getItem("patientI");
      this.name = JSON.parse(patientN);
      this.cid = JSON.parse(patientI);
    },
    //  // 点击提交上传评分，并且删除本地存储的患者信息
    // test() {
    //   console.log(11111111, this.name);
    //   localStorage.removeItem("patientN");
    //   localStorage.removeItem("patientI");
    // },
  },
  computed: {
    explain() {
      if (this.total < 8) {
        return "正常";
      }
      if (this.total >= 8 && this.total < 20) {
        return "可能有抑郁症";
      }
      if (this.total >= 20 && this.total <= 35) {
        return "肯定有抑郁症";
      }
      if (this.total > 35) {
        return "严重抑郁症";
      }
    },
    isdisabled() {
      if (
        this.radio == "" ||
        this.radio1 == "" ||
        this.radio2 == "" ||
        this.radio3 == "" ||
        this.radio4 == "" ||
        this.radio5 == "" ||
        this.radio6 == "" ||
        this.radio7 == "" ||
        this.radio8 == "" ||
        this.radio9 == "" ||
        this.radio10 == "" ||
        this.radio11 == "" ||
        this.radio12 == "" ||
        this.radio13 == "" ||
        this.radio14 == "" ||
        this.radio15 == "" ||
        this.radio16 == "" ||
        this.radio17 == "" ||
        this.radio18 == "" ||
        this.radio19 == "" ||
        this.radio20 == "" ||
        this.radio21 == "" ||
        this.radio22 == "" ||
        this.radio23 == ""
      ) {
        return true;
      }
      if (
        this.radio != "" ||
        this.radio1 != "" ||
        this.radio2 != "" ||
        this.radio3 != "" ||
        this.radio4 != "" ||
        this.radio5 != "" ||
        this.radio6 != "" ||
        this.radio7 != "" ||
        this.radio8 != "" ||
        this.radio9 != "" ||
        this.radio10 != "" ||
        this.radio11 != "" ||
        this.radio12 != "" ||
        this.radio13 != "" ||
        this.radio14 != "" ||
        this.radio15 != "" ||
        this.radio16 != "" ||
        this.radio17 != "" ||
        this.radio18 != "" ||
        this.radio19 != "" ||
        this.radio20 != "" ||
        this.radio21 != "" ||
        this.radio22 != "" ||
        this.radio23 != ""
      ) {
        return false;
      }
    },
    total() {
      return (
        this.radio * 1 +
        this.radio1 * 1 +
        this.radio2 * 1 +
        this.radio3 * 1 +
        this.radio4 * 1 +
        this.radio5 * 1 +
        this.radio6 * 1 +
        this.radio7 * 1 +
        this.radio8 * 1 +
        this.radio9 * 1 +
        this.radio10 * 1 +
        this.radio11 * 1 +
        this.radio12 * 1 +
        this.radio13 * 1 +
        this.radio14 * 1 +
        this.radio15 * 1 +
        this.radio16 * 1 +
        this.radio17 * 1 +
        this.radio18 * 1 +
        this.radio19 * 1 +
        this.radio20 * 1 +
        this.radio21 * 1 +
        this.radio22 * 1 +
        this.radio23 * 1
      );
    },
  },
  data() {
    return {
      ETID: "E2",
      isShow: false,
      name: "",
      cid: "",
      radio: "",
      radio1: "",
      radio2: "",
      radio3: "",
      radio4: "",
      radio5: "",
      radio6: "",
      radio7: "",
      radio8: "",
      radio9: "",
      radio10: "",
      radio11: "",
      radio12: "",
      radio13: "",
      radio14: "",
      radio15: "",
      radio16: "",
      radio17: "",
      radio18: "",
      radio19: "",
      radio20: "",
      radio21: "",
      radio22: "",
      radio23: "",
    };
  },
};
</script>

<style lang='scss' scoped>
.el-radio {
  margin: 10px;
}
body {
  background: rgb(220, 245, 179);
}
#box {
  width: 100%;
  margin: auto;
  text-align: left;
  margin-left: 30px;
  // background: rgb(206, 245, 175);
}
.label_color {
  color: rgb(202, 44, 207);
}
h1 {
  text-align: center;
}

h4 {
  color: rgb(140, 102, 245);
}
</style>

